import initialState from '../config/initialState'
import * as types from '../config/types'

export default function reducer(state = initialState.userSettings, action) {
  switch (action.type) {
    // получение настроек пользователя
    case types.userSettings.GET: {
      const user         = action.data
      user.isSupervisor  = user.userType === 5 ? 1 : 0

      return { ...state, ...user }
    }

    default:
      return state

  }
};
