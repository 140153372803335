import ThemeOptions from './ThemeOptions'
import vatsThemeOptions from './vatsThemeOptions'
import auth from './auth'
import poolSettings from './poolSettings'
import userSettings from './userSettings'
import { domains } from './domains'
import { groups } from './groups'
import { users } from './users'
import { operations } from './operations'
import apiErrors from './apiErrors'

export default {
  ThemeOptions,
  vatsThemeOptions,
  apiErrors,
  auth,
  poolSettings,
  userSettings,
  domains,
  groups,
  users,
  operations,
}