import React from 'react'

class Loader extends React.Component {
  render() {
    return (
      <div className="loader">
        <div className="spinner-block">
          <div className="spinner"></div>
          <div className="text">Загрузка...</div>
        </div>
      </div>
    )
  }
}

export default Loader;