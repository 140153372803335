// в редукторе принимается исходное состояние и экшен. В зависимости от типа экшена мы изменяем исходное состояние и возвращаем новое

import initialState from '../config/initialState'
import * as types from '../config/types'
import { clone } from 'helpers'

export function groups(state = initialState.groups, action) {
  switch (action.type) {
    // получение списка контактов пользователя
    case types.groups.GET: {
      return action.data
    }

    case types.groups.ADD: {
      const group    = action.data
      const newState = clone(state)
      newState.push(group)

      return newState
    }

    case types.groups.UPDATE: {
      const newGroup = action.data

      return clone(state).map(group => group.id === newGroup.id ? newGroup : group)
    }

    case types.groups.DELETE: {
      const id = action.data

      return clone(state).filter(group => group.id !== id)
    }

    default:
      return state
  }
}
