export default {
  apiErrors: [],
  auth: {
    userName: window.sessionStorage && window.sessionStorage.getItem('userName')
      ? window.sessionStorage.getItem('userName')
      : '',
    isSupervisor: 0,
    captcha_image: null,
    passwordResetMessage: '',
    setNewPasswordMessage: '',
  },
  // пользователь в сессии (текущий)
  userSettings: {
    id: null,
    name: null,
    displayName: '',
    userType: null,
    email: '',
    disabled: 0,
    domainsGroups: [],
    isSupervisor: 0,
    phoneNumber: ''
  },
  poolSettings: {
    id: null,
    name: '',
    domainPinLength: null,
    count: null,
  },
  domains: [],
  groups: [],
  users: [],
  operations: {
    total: null,
    list: [],
    actions: []
  },
  vatsThemeOptions: {
    enableMobileMenu: false,
    domainsCountCollapse: 50, //Количество доменов для отображения(в соответствии с фильтром), при котором группы разворачиваются.
  },
}