import React from 'react'

// преобразует массив объектов ошибок в строку
export function apiErrorsToString(apiErrors) {
  if (apiErrors) {
    let errorString = ''
    let i           = 0
    for (let error in apiErrors) {
      if (i > 0) errorString += ', '
      errorString += apiErrors[error]
      i++
    }
    return errorString
  }
}

// склонение в зависимости от числительного
export function declOfNum(number, words) {
  return words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
    ]
}

// сортировка по name
export function compareByName(a, b) {
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
  return 0
}

// сортировка по displayName
export function compareByDisplayName(a, b) {
  if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) return -1
  if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) return 1
  return 0
}

// сортировка по groupName
export function compareByGroupName(a, b) {
  if (a.groupName) {
    if (a.groupName.toLowerCase() < b.groupName.toLowerCase()) return -1
    if (a.groupName.toLowerCase() > b.groupName.toLowerCase()) return 1
  }
  return 0
}

// подготовка acdTypes группы для селектов
export function getAcdTypes() {
  const acdTypes = [
    { value: 'none', label: 'не задан' },
    { value: 'rand', label: 'Случайный' },
    { value: 'mia', label: 'Наиболее свободный' },
    { value: 'loa', label: 'Наименее занятый' },
    { value: 'hunt', label: 'Всем пользователям' },
  ]
  return acdTypes
}

export function formatNumber(number) {
  if (/\+?\d{10}/.test(number)) {
    return number.replace(
      /(\+?\d)(\d\d\d)(\d\d\d)(\d\d)(\d\d)/,
      '+7 ($2) $3-$4-$5',
    )
  }
  return number
}

export const reorder = (startIndex, endIndex) => (list) => {
  const result    = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

// Создание копии объекта
export function clone(from, target = null) {
  const isArr = from instanceof Array
  const to    = target ? target : isArr ? [] : {}
  for (let key of Object.getOwnPropertyNames(from)) {
    if (from.hasOwnProperty(key) && (!isArr || key !== 'length')) {
      if (from[key] instanceof Object) {
        to[key] = from[key] instanceof Array ? clone(from[key], []) : clone(from[key])
      } else {
        Object.defineProperty(to, key, Object.getOwnPropertyDescriptor(from, key))
      }
    }
  }
  return to
}

export const not = (x) => !x

const mapValues = (fn, obj) =>
  Object.keys(obj).reduce((acc, key) => {
    acc[key] = fn(obj[key])
    return acc
  }, {})

// https://ramdajs.com/docs/#applySpec
export const applySpec = (spec) => {
  spec = mapValues((v) => (typeof v == 'function' ? v : applySpec(v)), spec)
  return (...args) => mapValues((f) => f(...args), spec)
}

export const pipe = (functions) => (arg) =>
  functions.reduce((acc, f) => f(acc), arg)

export const append = (x) => (array) => [...array, x]
export const remove = (elementOnRemove) => (list) =>
  list.filter((element) => element !== elementOnRemove)
export const prop   = (name) => (object) => object?.[name]

export const debounce = (f, ms) => {
  let timeout = null
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(f, ms, ...args)
  }
}

export const useGenerator = (generator, ...args) => {
  // now generator should be pure function
  const pathI               = React.useRef(generator(...args)).current
  const [value, setValue]   = React.useState(() => pathI.next().value)
  const [isDone, setIsDone] = React.useState()
  const next                = (arg) => {
    const { value: newValue, done } = pathI.next(arg)
    setValue(newValue)
    if (done) {
      setIsDone(true)
    }
  }
  return { value, isDone, next }
}

export const delay = (time = 1000) =>
  new Promise((resolve) => setTimeout(resolve, time))

export const numericalDeclination = ({ few, one, two }) => {
  const titles = [one, two, few]
  return (number) => {
    const cases = [2, 0, 1, 1, 1, 2]

    return titles[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
      ]
  }
}
export const last                 = (array) => array[array.length - 1]

export const useOnClickOutside = (f) => {
  const ref     = React.useRef(null)
  const memoF   = React.useRef(f)
  memoF.current = f
  React.useEffect(() => {
    const handler = ({ target }) => {
      if (!ref.current?.contains(target)) {
        memoF.current?.(false)
      }
    }
    document.body.addEventListener('click', handler)
    return () => document.body.removeEventListener('click', handler)
  }, [])
  return ref
}

// from map
export const proportion = (a, b) => {
  const diff = b - a
  // to map
  return (c, d) => {
    const k = (d - c) / diff
    return (x) => k * x
  }
}

// Скачивает файл формата {data:base64, mimeType:string} формат результат функции b64toBlob
export const downloadFile = (file, name) => {
  const data = URL.createObjectURL(b64toBlob(file.data, file.mimeType))
  let a      = document.createElement('a')
  a.href     = data
  a.download = name
  a.click()
  URL.revokeObjectURL(data)
}

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays     = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

// source from https://github.com/facebook/react/blob/a9b035b0c2b8235405835beca0c4db2cc37f18d0/packages/shared/shallowEqual.js
export const shallowEqual = (objA) => (objB) => {
  const hasOwnProperty = Object.prototype.hasOwnProperty

  if (Object.is(objA, objB)) {
    return true
  }

  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false
  }

  const keysA = Object.keys(objA)
  const keysB = Object.keys(objB)

  if (keysA.length !== keysB.length) {
    return false
  }

  // Test for A's keys different from B.
  for (let i = 0; i < keysA.length; i++) {
    if (
      !hasOwnProperty.call(objB, keysA[i]) ||
      !Object.is(objA[keysA[i]], objB[keysA[i]])
    ) {
      return false
    }
  }

  return true
}
