// в редукторе принимается исходное состояние и экшен. В зависимости от типа экшена мы изменяем исходное состояние и возвращаем новое

import initialState from '../config/initialState'
import * as types from '../config/types'
import { clone } from 'helpers/Helpers'

export function domains(state = initialState.domains, action) {
  switch (action.type) {
    // получение списка контактов пользователя
    case types.domains.GET: {
      return action.data
    }

    // получение списка контактов пользователя
    case types.domains.GET_ONE: {
      const newDomain = action.data

      return clone(state).map(domain => domain.name === newDomain.name ? newDomain : domain)
    }

    case types.domains.UPDATE: {
      const newDomain = action.data

      return clone(state).map(domain => domain.name === newDomain.name ? newDomain : domain)
    }


    case types.domains.UPDATE_PIN: {
      const domains       = clone(state)
      const domainName      = action.result.domainName
      const newPin        = action.result.newPin
      const updatedDomain = domains.find(d => d.name == domainName)
      if (updatedDomain) {
        updatedDomain.domainPin = newPin
      }

      return domains
    }

    case types.domains.UPDATE_LIST: {
      const updatedDomains = action.data

      return clone(state).map(domain => updatedDomains.find(updated => updated.name === domain.name) || domain)

    }

    default:
      return state
  }
}
