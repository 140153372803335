import initialState from '../config/initialState'
import * as types from '../config/types'


export default function reducer(state = initialState.auth, action) {
  switch (action.type) {

    case types.auth.LOGIN: {
      const { user_name: userName, is_supervisor: isSupervisor } = action.data
      if (window.sessionStorage) {
        window.sessionStorage.setItem('userName', userName)
      }

      return { ...state, userName, isSupervisor }
    }

    case types.auth.CHECK: {
      let user_name, is_supervisor, captcha_image
      user_name       = action.data.user_name
      captcha_image = action.data.captcha_image

      if (window.sessionStorage) {
        window.sessionStorage.setItem('userName', user_name)
      }

      return { ...state, userName: user_name, captcha_image: captcha_image }
    }

    case types.auth.LOGOUT: {
      if (window.sessionStorage) {
        window.sessionStorage.clear()
      }
      return { ...state, userName: '' }
    }

    case types.app.ERROR: {
      const { error } = action
      if (error.status === 401) {
        var newState = Object.assign({}, state, { userName: '' })
      } else {
        var newState = Object.assign({}, state)
      }
      return newState
    }

    case types.auth.RESET_PASSWORD: {
      const newState = Object.assign({}, state, { passwordResetMessage: action.data.message })
      return newState
    }

    case types.auth.SET_NEW_PASSWORD: {
      const newState = Object.assign({}, state, { setNewPasswordMessage: action.data.message })
      return newState
    }

    // переключение уведомления о стоимости услуг
    case types.auth.SERVICE_CHARGE_NOTICE: {
      const newState = Object.assign({}, state, { serviceChargeNotice: action.notice })
      console.log('auth newState', newState)
      return newState
    }

    default:
      return state

  }
};