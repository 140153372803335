export const app = {
  ERROR: 'vats/app/error',
}

export const auth = {
  LOGIN: 'vats/auth/login',
  CHECK: 'vats/auth/check',
  LOGOUT: 'vats/auth/logout',
  RESET_PASSWORD: 'vats/auth/reset_password',
  SET_NEW_PASSWORD: 'vats/auth/set_new_password',
  SERVICE_CHARGE_NOTICE: 'vats/auth/service_charge_notice',
}

export const pool = {
  SETTINGS: {
    GET: 'vats/pool/settings/get',
  },
}

export const userSettings = {
  GET: 'vats/pool/auth_user_settings/get',
}

export const domains = {
  GET: 'vats/domains/get',
  GET_ONE: 'vats/domains/get_one',
  UPDATE: 'vats/domains/update',
  UPDATE_PIN: 'vats/domains/update_pin',
  UPDATE_LIST: 'vats/domains/update_list',
  AUTH: 'vats/domains/auth',
  GROUPS: {
    UPDATE: 'vats/domains/groups/update',
  },
}

export const groups = {
  GET: 'vats/groups/get',
  ADD: 'vats/groups/add',
  UPDATE: 'vats/groups/update',
  DELETE: 'vats/groups/delete',
}
export const users  = {
  GET: 'vats/users/get',
  ADD: 'vats/users/add',
  UPDATE: 'vats/users/update',
  DELETE: 'vats/users/delete',
  GROUPS: {
    ADD: 'vats/users/groups/add',
    DELETE: 'vats/users/groups/delete',
  },
}

export const operations = {
  GET: 'vats/operations/get',
  GET_APPEND: 'vats/operations/get_append',
  ACTIONS: {
    GET: 'vats/operations/actions/get',
  },
}