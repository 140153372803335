import initialState from '../config/initialState'
import * as types from '../config/types'

export const getPoolSettings = state => state.poolSettings

export default function reducer(state = initialState.poolSettings, action) {
  switch (action.type) {
    case types.pool.SETTINGS.GET: {
      const { data:poolSettings } = action
      return Object.assign({}, state, poolSettings)
    }

    default:
      return state
  }
}
