import initialState from '../config/initialState'
import * as types from '../config/types'
import { clone } from 'helpers/Helpers'

export function users(state = initialState.users, action) {
  switch (action.type) {
    // получение списка контактов пользователя
    case types.users.GET: {
      return action.data
    }

    case types.users.ADD: {
      const newUser = action.data
      const newList = clone(state)
      newList.push(newUser)
      return newList

    }

    case types.users.UPDATE: {
      const newUser = action.data
      return clone(state.map(user => user.name === newUser.name ? newUser : user))
    }

    case types.users.DELETE: {
      const deletedName = action.data

      return clone(state.filter(user => user.name !== deletedName))
    }

    case types.users.GROUPS.ADD: {
      const user = action.result
      console.log('types.groups.GROUPS.ADD', user)
      return state
    }

    case types.users.GROUPS.DELETE: {
      const user = action.result
      console.log('types.groups.GROUPS.DELETE', user)
      return state
    }


    default:
      return state

  }
}
