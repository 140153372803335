// в редукторе принимается исходное состояние и экшен. В зависимости от типа экшена мы изменяем исходное состояние и возвращаем новое

import initialState from '../config/initialState'
import * as types from '../config/types'

export function operations(state = initialState.operations, action) {
  switch (action.type) {
    // получение списка контактов пользователя
    case types.operations.GET: {
      const operations = action.data
      console.log('types.operations.GET', operations)

      return { ...state, total:operations.totalCount, list:operations.data }
    }

    case types.operations.GET_APPEND: {
      const operations = action.data
      console.log('types.operations.GET', operations)

      return { ...state, list: state.list.concat(operations.data) }
    }

    case types.operations.ACTIONS.GET: {
      const actions = action.data
      console.log('types.operations.ACTIONS.GET', actions)

      return { ...state, actions }
    }

    default:
      return state
  }
}
