import React, { Fragment, lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import { Redirect, Route, withRouter } from 'react-router-dom'

import ResizeDetector from 'react-resize-detector'
import { ToastContainer } from 'react-toastify'
import Loader from './components/Loader.js'

const Admin = lazy(() => import('./Pages/Admin/admin'));
const Login = lazy(() => import('./Pages/Login'));

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        let {
            enableMobileMenu,
            authorizedUserName,
        } = this.props;

        return (
            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <Fragment>
                        <div className={cx("app-container",{'menu-toggled': enableMobileMenu},)}>
                            {
                                enableMobileMenu &&
                                <div className="modal-backdrop fade show"></div>
                            }

                            <Suspense fallback={
                                <div className="mt-3">
                                    <Loader/>
                                </div>
                            }>
                                {/* Admin */}
                                <Route path="/admin" component={Admin}/>
                                {/* Auth */}
                                <Route path="/login" component={Login}/>

                            </Suspense>

                            <Route exact path="/" render={() => (
                                <Redirect to={authorizedUserName ? "/admin" : "/login"}/>
                            )}/>

                            <ToastContainer/>
                        </div>
                    </Fragment>
                )}
            />
        )
    }
}

const mapStateToProp = state => ({
    enableMobileMenu: state.vatsThemeOptions.enableMobileMenu,
    authorizedUserName: state.auth.userName
});

export default withRouter(connect(mapStateToProp)(App));