import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import { HashRouter } from 'react-router-dom';
import './assets/base.scss';
import App from './App';
import configureStore from './config/configureStore';
import initialState from './config/initialState';
import { Provider } from 'react-redux';

const store = configureStore(initialState);
const rootElement = document.getElementById('root');

const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <HashRouter>
                <Component />
            </HashRouter>
        </Provider>,
        rootElement
    );
};

renderApp(App);


serviceWorker.unregister();

